@charset 'UTF-8';
/* Slider */
.slick-loading .slick-list {
    background: #fff url("../img/ajax-loader.gif") center center no-repeat;
}

/* Icons */
@font-face {
    font-family: 'slick';
    font-weight: normal;
    font-style: normal;

    src: url("../fonts/slick.eot");
    src: url("../fonts/slick.eot?#iefix") format('embedded-opentype'), url("../fonts/slick.woff") format('woff'), url("../fonts/slick.ttf") format('truetype'), url("../fonts/slick.svg#slick") format('svg');
}
/* Arrows */
.slick-next,
.slick-prev {
    font-size: 0;
    line-height: 0;

    position: absolute;
    top: 50%;

    display: block;

    width: 20px;
    height: 20px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);

    cursor: pointer;

    color: transparent;
    border: none;
    outline: none;
    background: transparent;
}
.slick-next:focus,
.slick-next:hover,
.slick-prev:focus,
.slick-prev:hover {
    color: transparent;
    outline: none;
    background: transparent;
}
.slick-next:focus:before,
.slick-next:hover:before,
.slick-prev:focus:before,
.slick-prev:hover:before {
    opacity: 1;
}
.slick-next.slick-disabled:before,
.slick-prev.slick-disabled:before {
    opacity: 0.25;
}

.slick-next:before,
.slick-prev:before {
    font-family: 'slick';
    font-size: 20px;
    line-height: 1;

    opacity: 0.75;
    color: #507bf5;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
    left: -6px;
    z-index: 100;
}
[dir='rtl'] .slick-prev {
    right: -25px;
    left: auto;
}
.slick-prev:before {
    content: "\f104";
    font: 20px/1 'FontAwesome';
    background: linear-gradient(to right, #507bf5 0%,#04c3e1 100%);
    box-shadow: 1px 1px 30px 0 rgba(0,0,0,0.3);
    color: #ffffff;
    border-radius: 50%;
    padding: 9.5px 17px;
    width: 100%;
    height: 100%;
}
[dir='rtl'] .slick-prev:before {
    content: '→';
}

.slick-next {
    right: 15px;
}
[dir='rtl'] .slick-next {
    right: auto;
    left: -25px;
}
.slick-next:before {
    content: "\f105";
    font: 20px/1 'FontAwesome';
    background: linear-gradient(to right, #507bf5 0%,#04c3e1 100%);
    box-shadow: 1px 1px 30px 0 rgba(0,0,0,0.3);
    color: #ffffff;
    border-radius: 50%;
    padding: 9.5px 17px;
    width: 100%;
    height: 100%;
}
[dir='rtl'] .slick-next:before {
    content: '←';
}

/* Dots */
.slick-dotted.slick-slider {
    margin-bottom: 30px;
}

.slick-dots {
    position: absolute;
    bottom: -25px;

    display: block;

    width: 100%;
    padding: 0;
    margin: 0;

    list-style: none;

    text-align: center;
}
.slick-dots li {
    position: relative;

    display: inline-block;

    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;

    cursor: pointer;
}
.slick-dots li button {
    font-size: 0;
    line-height: 0;

    display: block;

    width: 20px;
    height: 20px;
    padding: 5px;

    cursor: pointer;

    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}
.slick-dots li button:focus,
.slick-dots li button:hover {
    outline: none;
}
.slick-dots li button:focus:before,
.slick-dots li button:hover:before {
    opacity: 1;
}
.slick-dots li button:before {
    font-family: 'slick';
    font-size: 6px;
    line-height: 20px;

    position: absolute;
    top: 0;
    left: 0;

    width: 20px;
    height: 20px;

    content: '•';
    text-align: center;

    opacity: 0.25;
    color: black;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
    opacity: 0.75;
    color: black;
}
