@import "bootstrap.min.css";
@import "plugins/animate.css";
@import "plugins/slick.css";
@import "plugins/slick-theme.css";
@import "plugins/magnific-popup.css";

@import "custom.pcss";

/* ----------------------------------

Name: main.css
Version: 1.0

-------------------------------------

Table of contents

    01. Navbar
    02. Slider
    03. Features
    04. Some Facts
    05. Project
    06. Video
    07. Portfolio
    08. Benifits
    09. Team
    10. Get Started
    11. Testimonials
    12. Project-2
    13. Our Price
    14. Clients
    15. Contact Us
    16. Footer
    17. Page Loading
    18. Media Query
*/

body {
	font-family: 'Open Sans', sans-serif;
	font-weight: 400;
	background: #f9faff url("../img/shadow.png") center -50rem repeat-y;
}

.btn {
	text-transform: uppercase;
	background: linear-gradient(to right, #1bb7d0 0%, #53ccf5 100%);
	border: 1px solid #1bb7d0;
	box-shadow: 1px 1px 30px 0 rgba(0, 0, 0, 0.2);
	text-decoration: none;
	color: #fff;
	border-radius: 65px;
	padding: 12px 30px;
	font-size: 15px;
	font-weight: 600;
	display: inline-block;

	&:hover {
		color: #1bb7d0;
		background: #fff;
	}
}


/* --------------------------------------------------
[1. Start Navbar]
-------------------------------------------------- */
.navbar {
	padding: 15px 0;
	background: rgba(255, 255, 255, .75) !important;

	.nav-item {
		padding: 0;
		margin: 0;

		.nav-link {
			padding: 0;
			margin: 0 0 0 45px;
			font-size: 16px;
			color: #575265;
			font-weight: 400;
			transition: all .4s ease-in-out;

			&:hover {
				color: #507bf5;
			}
		}
	}

	.btn {
		font-family: 'Montserrat', sans-serif;
		text-transform: uppercase;
		background: transparent;
		border: 1px solid #762b8d;
		color: #762b8d;
		text-decoration: none;
		border-radius: 65px;
		padding: 10px 30px;
		margin-left: 30px;
		font-size: 14px;
		font-weight: 700;
		display: inline-block;
		transition: all .2s ease-in-out;

		&:hover {
			background: #762b8d;
			color: #fff;
			box-shadow: 1px 1px 30px 0 rgba(0, 0, 0, 0);
		}
	}

	.header-lang {
		display: inline-flex;
		align-items: center;

		.nav-link {
			vertical-align: middle;
		}

		.dropdown-toggle:after {
			vertical-align: middle;
			margin-left: .75em;
		}

		.icon {
			font-size: 1.5em;
		}

		.dropdown-menu {
			min-width: 0;
			width: 100%;

			li {
				padding: .25em 0;
				text-align: center;
			}
		}
	}
}


/* --------------------------------------------------
[2. Start Slider]
-------------------------------------------------- */
.slider {
	height: 100vh;

	.content {
		margin-top: 50px;
	}

	h1 {
		font-family: 'Montserrat', sans-serif;
		line-height: 55px;
		margin: 0;
		padding: 0;
		font-size: 34px;
		color: #52cbf5;
		font-weight: 700;

		strong {
			font-size: 48px;
			color: #762b8d;
			text-transform: uppercase;
			line-height: 1;
			text-align: left;
			font-weight: 500;
		}
	}

	p {
		font-size: 18px;
		font-weight: 400;
		color: #000;
		line-height: 26px;
		padding: 0;
		margin: 10px 0 30px;
	}

	.btn {
		text-transform: uppercase;
		background: linear-gradient(to right, #1bb7d0 0%, #53ccf5 100%);
		border: 1px solid #1bb7d0;
		box-shadow: 1px 1px 30px 0 rgba(0, 0, 0, 0.2);
		text-decoration: none;
		color: #fff;
		border-radius: 65px;
		padding: 12px 30px;
		font-size: 15px;
		font-weight: 600;
		margin-top: .5rem;
		display: inline-block;

		&:hover {
			color: #1bb7d0;
			background: #fff;
		}
	}
}


/* --------------------------------------------------
[3. Start Features]
-------------------------------------------------- */
section {
	.heading {
		font-size: 30px;
		color: #3b566e;
		font-weight: 700;
		margin-bottom: 15px;
		padding: 0;
	}

	.line {
		background: linear-gradient(to right, #507bf5 0%, #04c3e1 100%);
		height: 2px;
		width: 50px;
		margin-bottom: 50px;
		display: inline-block;
	}
}

.features {
	background: url("../img/bg/feature-bg.png") top center no-repeat;
	background-size: cover;
	padding: 120px 0;

	.box {
		background: #fff;
		box-shadow: 1px 1px 25px 0 rgba(0, 0, 0, 0.05);
		border-radius: 15px;
		padding: 60px 30px;
		transition: all .4s ease-in-out;

		&:hover {
			transform: translateY(-15px);
			box-shadow: 1px 1px 25px 0 rgba(0, 0, 0, 0.09);
		}

		h3 {
			font-size: 20px;
			color: #3b566e;
			font-weight: 600;
			margin: 25px 0 15px;
			padding: 0;
		}

		p {
			font-size: 15px;
			font-weight: 400;
			color: #919aaf;
			line-height: 26px;
			margin: 0;
			padding: 0;
		}
	}
}


/* --------------------------------------------------
[4. Start Some Facts]
-------------------------------------------------- */
.some-facts {
	background: url("../img/bg/some-facts.png") center center no-repeat;
	background-size: cover;
	padding: 120px 0;

	.heading {
		font-size: 30px;
		color: #fff;
		font-weight: 700;
		margin-bottom: 15px;
		padding: 0;
	}

	.line {
		background: linear-gradient(to right, #ddd 0%, #fff 100%);
		height: 2px;
		width: 50px;
		margin-bottom: 50px;
		display: inline-block;
	}

	.items {
		position: relative;
		color: #fff;

		h3 {
			font-size: 30px;
			font-weight: 800;
			padding: 0;
			margin: 20px 0 2px;
		}

		h4 {
			font-size: 17px;
			font-weight: 500;
			color: #fff;
			padding: 0;
			margin: 0;
		}
	}
}


/* --------------------------------------------------
[5. Start Project]
-------------------------------------------------- */
.project {
	padding: 120px 0;

	.box {
		margin-bottom: 30px;
	}

	.right {
		span {
			font-size: 16px;
			color: #557ff5;
			font-weight: 600;
		}

		h2 {
			font-size: 25px;
			color: #3b566e;
			font-weight: 600;
			line-height: 32px;
		}

		p {
			font-size: 16px;
			font-weight: 400;
			color: #919aaf;
			line-height: 26px;
			margin: 0;
			padding: 0;
			margin-top: 15px;
			margin-bottom: 40px;
		}

		.btn {
			background: linear-gradient(to right, #507bf5 0%, #04c3e1 100%);
			text-decoration: none;
			color: #fff;
			border-radius: 65px;
			padding: 10px 45px;
			font-size: 15px;
			font-weight: 600;
		}
	}
}


/* --------------------------------------------------
[6. Start Video]
-------------------------------------------------- */
.video {
	background: url("../img/bg/video-bg.png") center center no-repeat;
	background-size: cover;
	padding: 120px 0;
}

.video-icon .icon {
	background: #fff;
	color: #507bf5;
	display: inline;
	font-size: 20px;
	padding: 22px 28px;
	border-radius: 200px;
	position: relative;
}

/* Pulse Animation */
.pulse {
	animation: pulse 1s infinite;
	animation-duration: 2s;
}

/* Keyframes */
@keyframes pulse {
	0% {
		box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
	}

	70% {
		box-shadow: 0 0 0 50px rgba(255, 255, 255, 0);
	}

	100% {
		box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
	}
}


/* --------------------------------------------------
[7. Start Portfolio]
-------------------------------------------------- */
.portfolio .single-portfolio-item {
	position: relative;
	padding: 0;

	.overlay {
		position: absolute;
		top: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		background: #fff;
		color: #333;
		opacity: 0;
		transition: all 0.7s ease-in-out;

		.content {
			font-size: 20px;
			position: absolute;
			text-decoration: none;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			color: #fff;
			transition: all 0.2s ease-in;

			h3 {
				font-size: 18px;
				font-weight: 500;
				color: #444;
				padding: 0;
				margin: 0;
			}

			p {
				font-size: 14px;
				font-weight: 400;
				color: #507bf5;
				padding: 0;
				margin: 5px 0 0;
			}

			a {
				color: #555;
				text-decoration: none;
				font-size: 20px;
				padding: 0;
				margin: 0;
			}
		}
	}

	&:hover .overlay {
		opacity: 0.95;
	}
}


/* --------------------------------------------------
[8. Start Benefits]
-------------------------------------------------- */
.benefits {
	padding: 120px 0;
	background: #f1f7ff;

	.box {
		padding: 0 30px;

		h3 {
			font-size: 20px;
			color: #3b566e;
			font-weight: 600;
			margin: 20px 0 10px;
			padding: 0;
		}

		p {
			font-size: 15px;
			font-weight: 400;
			color: #919aaf;
			line-height: 26px;
			margin: 0;
			padding: 0;
		}
	}
}

.mb-30 {
	margin-bottom: 40px;
}


/* --------------------------------------------------
[9. Start Team]
-------------------------------------------------- */
.our-team {
	padding: 120px 0;
}

.our-team .box {
	box-shadow: 1px 1px 25px 0 rgba(0, 0, 0, 0.05);
	background: #fff;
	border-radius: 5px;
}

.our-team .box .image {
	position: relative;
}

.our-team .box .image img {
	width: 100%;
}

.our-team .box .image .social-icon {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
	background: linear-gradient(to right, #507bf5 0%, #04c3e1 100%);
	box-shadow: 1px 1px 30px 0 rgba(0, 0, 0, 0.2);
	padding: 13px 20px 10px;
	border-radius: 35px;
	margin-top: 100px;
	opacity: 0;
	transition: all 0.4s ease-in-out;
}

.our-team .box .image .social-icon i {
	font-size: 20px;
	text-transform: none;
	color: #fff;
	padding: 0 10px;
}

.our-team .box:hover .social-icon {
	opacity: 1;
	margin-top: 0;
}

.our-team .info {
	padding: 30px 0;
}

.our-team .info h3 {
	padding: 0;
	margin: 0;
	font-size: 20px;
	font-weight: 600;
	color: #3b566e;
}

.our-team .info span {
	font-size: 14px;
	font-weight: 400;
	color: #8c9399;
}

/* --------------------------------------------------
[10. Start Get Started]
-------------------------------------------------- */
.get-started {
	background: url("../img/bg/get-started.png") center center no-repeat;
	background-size: cover;
	padding: 120px 0;
	color: #fff;

	span {
		font-size: 16px;
		font-weight: 400;
		text-transform: uppercase;
	}

	h3 {
		font-size: 25px;
		font-weight: 400;
		margin: 10px 0 0;
	}

	.btn {
		background: #fff;
		box-shadow: 1px 1px 30px 0 rgba(0, 0, 0, 0.1);
		text-decoration: none;
		color: #1e3056;
		border-radius: 65px;
		padding: 10px 45px;
		font-size: 15px;
		font-weight: 600;
		margin-top: 25px;
		display: inline-block;
	}
}


/* --------------------------------------------------
[11. Start Testimonials]
-------------------------------------------------- */
.testimonials {
	padding: 120px 0;

	.box {
		background: #fff;
		border-radius: 8px;
		background: linear-gradient(to left, rgba(4, 195, 225, 1) 0%, rgba(4, 195, 225, 1) 12%, rgba(4, 195, 225, 1) 47%, rgb(80, 123, 245) 100%) left bottom #fff no-repeat;
		background-size: 100% 5px;
		box-shadow: 1px 1px 25px 0 rgba(0, 0, 0, 0.05);
		position: relative;
		margin: 48px 15px 0;
		padding: 90px 30px 50px;

		img {
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			top: -47px;
		}

		h3 {
			font-size: 20px;
			color: #3b566e;
			font-weight: 600;
			margin: 0;
			padding: 0;
		}

		span {
			font-size: 14px;
			color: #8c9399;
			font-weight: 400;
		}

		p {
			font-size: 15px;
			font-weight: 400;
			color: #919aaf;
			line-height: 26px;
			padding: 0;
			margin: 20px 0 0;
		}
	}
}

.slick-list {
	outline: none !important;
}

.slick-slide:focus {
	outline: none;
}

.slick-dots li button:before {
	font-family: "slick";
	width: 12px;
	height: 12px;
	padding: 0;
	border: none;
	border-radius: 100%;
	background: linear-gradient(to right, #507bf5 0%, #04c3e1 100%);
	text-indent: -9999px;
	margin-top: 40px;
}


/* --------------------------------------------------
[12. Start Project-2]
-------------------------------------------------- */
.project-2 {
	padding: 80px 0 120px;
}

.project-2 .left span {
	font-size: 16px;
	color: #557ff5;
	font-weight: 600;
}

.project-2 .left h2 {
	font-size: 25px;
	color: #3b566e;
	font-weight: 600;
	line-height: 32px;
}

.project-2 .left p {
	font-size: 16px;
	font-weight: 400;
	color: #919aaf;
	line-height: 26px;
	padding: 0;
	margin: 15px 0 40px;
}

.project-2 .left .btn {
	background: linear-gradient(to right, #507bf5 0%, #04c3e1 100%);
	text-decoration: none;
	color: #fff;
	border-radius: 65px;
	padding: 10px 45px;
	font-size: 15px;
	font-weight: 600;
}


/* --------------------------------------------------
[13. Start Our Price]
-------------------------------------------------- */
svg.icon {
	width: 1em;
	height: 1em;
	fill: currentColor;
}

.our-price {
	padding: 120px 0;
	margin: 0 0 -40px;

	.box {
		position: relative;
		background: #fff;
		border-radius: 7px;
		box-shadow: 1px 1px 25px 0 rgba(0, 0, 0, 0.05);
		margin: 30px 0 60px;
		transition: box-shadow .5s ease-in-out;

		&:hover {
			box-shadow: 1px 1px 25px 0 rgba(0, 0, 0, 0.09);
		}

		.top {
			color: #212529;
			text-decoration: none;
		}

		.icon-wrap {
			display: inline-flex;
			align-items: center;
			justify-content: center;
			margin-top: -44px;
			width: 88px;
			height: 88px;
			border-radius: 88px;
			background: #fff;
			font-size: 50px;
		}

		.icon {
			color: #666;
		}

		.best {
			position: absolute;
			right: -5px;
			top: 25px;
			font-family: 'Montserrat', sans-serif;
			text-transform: uppercase;
			font-size: .5em;
			font-weight: 700;
			transform: rotate(45deg);
			background: #f7147e;
			color: #fff;
			padding: 0 4px;
			height: 20px;
			line-height: 20px;
			text-align: center;
			width: 80px;

			&:before,
			&:after {
				content: '';
				position: absolute;
				top: 0;
				height: 0;
				width: 0;
				border: 10px solid transparent;
				color: #f7147e;
				border-bottom-color: currentColor;
			}

			&:before {
				left: -20px;
				border-right-color: currentColor;
			}

			&:after {
				right: -20px;
				border-left-color: currentColor;
			}
		}

		h3 {
			font-family: 'Montserrat', sans-serif;
			font-size: 26px;
			font-weight: 900;
			margin-top: 10px;
			color: #666;
			text-transform: uppercase;
		}

		p {
			margin: 0 5%;
			line-height: 24px;
			min-height: 96px;
		}

		ul {
			border-top: 1px solid #666;
			margin: 0;
			padding: 40px 35px 0 15px;
			text-align: left;
			background-image: linear-gradient(to top, rgba(249, 250, 255, .03) 0%, rgba(0, 0, 0, .03) 100%);

			li {
				display: flex;
				align-items: baseline;
				list-style: none;
				font-size: 15px;
				color: #525252;
				padding: 6px 0;

				.icon {
					flex: 0 0 auto;
					position: relative;
					top: 2px;
					margin-right: 20px;
				}
			}

			a {
				text-decoration: underline;
				color: #555;
				transition: all .2s ease-in-out;

				&:hover {
					text-decoration: none;
				}
			}
		}

		.top-btn {
			background: #fff;
			border-radius: 65px;
			padding: 10px 0;
			width: 60%;
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			top: -17px;
			font-size: 13px;
			line-height: 20px;
			font-weight: 600;
			display: inline-block;
			text-transform: uppercase;
		}

		.btn {
			font-family: 'Montserrat', sans-serif;
			background: #666;
			border: 1px solid #666;
			text-transform: uppercase;
			text-decoration: none;
			color: #fff;
			border-radius: 65px;
			padding: 9px 45px;
			font-size: 18px;
			font-weight: 600;
			display: inline-block;
			margin-top: 20px;
			margin-bottom: -22px;
			min-width: 200px;
			transition: all .2s ease-in-out;
		}

		&.box-blue {
			.icon {
				color: #21acc2;
			}

			h3 {
				color: #21acc2;
			}

			ul {
				border-top-color: #21acc2;
			}

			.btn {
				background: #21acc2;
				border-color: #21acc2;
			}

			> .btn:hover,
			.top:hover .btn {
				background: #fff;
				color: #21acc2;
			}
		}

		&.box-purple {
			.icon {
				color: #772b8e;
			}

			h3 {
				color: #772b8e;
			}

			ul {
				border-top-color: #772b8e;
			}

			.btn {
				background: #772b8e;
				border-color: #772b8e;
			}

			> .btn:hover,
			.top:hover .btn {
				background: #fff;
				color: #772b8e;
			}
		}

		&.box-pink {
			.icon {
				color: #f7147e;
			}

			h3 {
				color: #f7147e;
			}

			ul {
				border-top-color: #f7147e;
			}

			.btn {
				background: #f7147e;
				border-color: #f7147e;
			}

			> .btn:hover,
			.top:hover .btn {
				background: #fff;
				color: #f7147e;
			}
		}

		&.box-orange {
			.icon {
				color: #fa891f;
			}

			h3 {
				color: #fa891f;
			}

			ul {
				border-top-color: #fa891f;
			}

			.btn {
				background: #fa891f;
				border-color: #fa891f;
			}

			> .btn:hover,
			.top:hover .btn {
				background: #fff;
				color: #fa891f;
			}
		}

		strong {
			font-family: 'Montserrat', sans-serif;
			font-weight: 800;
			text-transform: uppercase;
			font-size: 1.1em;

			&.blue {
				color: #21acc2;
			}

			&.purple {
				color: #772b8e;
			}

			&.pink {
				color: #f7147e;
			}

			&.orange {
				color: #fa891f;
			}
		}
	}
}


/* --------------------------------------------------
[14. Start Clients]
-------------------------------------------------- */
.clients {
	padding: 120px 0;
	background: url("../img/bg/clients.png") center center no-repeat;
	background-size: cover;

	.item {
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0 20px;
		height: 4rem;

		img {
			max-height: 4rem;
			max-width: 100%;
			filter: grayscale(1) brightness(.4) invert(1);
			transition: filter .2s;

			&:hover {
				filter: none;
			}
		}
	}
}


/* --------------------------------------------------
[15. Start Contact Us]
-------------------------------------------------- */
.contact {
	padding: 120px 0;
}

.contact .heading h2 {
	font-size: 30px;
	color: #3b566e;
	font-weight: 700;
	margin-bottom: 15px;
	padding: 0;
}

.contact .line {
	background: linear-gradient(to right, #507bf5 0%, #04c3e1 100%);
	height: 2px;
	width: 50px;
	margin-bottom: 50px;
	display: inline-block;
}

.contact .form-control {
	padding: 25px;
	font-size: 13px;
	margin-bottom: 10px;
	background: #f1f1f5;
	border: 0;
	border-radius: 10px;
}

.contact select.form-control {
	height: 50px;
	padding: 5px 25px;
}

.contact button.btn {
	padding: 10px;
	border-radius: 10px;
	font-size: 15px;
	background: linear-gradient(to right, #507bf5 0%, #04c3e1 100%);
	color: #fff;
}

.contact .title h3 {
	font-size: 18px;
	font-weight: 600;
}

.contact .title p {
	font-size: 14px;
	font-weight: 400;
	color: #999;
	line-height: 1.6;
	margin: 0 0 40px;
}

.contact .content .info {
	margin-top: 30px;
}

.contact .content .info i {
	font-size: 30px;
	padding: 0;
	color: #02434b;
	margin: 0 20px 0 0;
}

.contact .content .info h4 {
	font-size: 13px;
	line-height: 1.4;
}

.contact .content .info h4 span {
	font-size: 13px;
	font-weight: 300;
	color: #999;
}

/* --------------------------------------------------
[xx. Content page]
-------------------------------------------------- */
.content-page {
	min-height: 100vh;
	padding: 150px 0 50px;

	h1 {
		font-family: 'Montserrat', sans-serif;
		line-height: 55px;
		margin: 0;
		padding: 0;
		font-size: 34px;
		color: #52cbf5;
		font-weight: 700;

		strong {
			font-size: 48px;
			color: #762b8d;
			text-transform: uppercase;
			line-height: 1;
			text-align: left;
			font-weight: 500;
		}
	}

	p {
		font-size: 18px;
		font-weight: 400;
		color: #000;
		line-height: 26px;
		padding: 0;
		margin: 10px 0 30px;

		&.card-desc {
			color: #8491b1;
			font-size: 1rem;
		}
	}
}


/* --------------------------------------------------
[16. Start Footer]
-------------------------------------------------- */
.footer {
	padding: 60px 0;
	background: #2e3444;
}

.footer p {
	font-size: 15px;
	font-weight: 500;
	color: #fff;
	margin: 20px 0 0;
}

/* --------------------------------------------------
[17. Start Page Loading]
-------------------------------------------------- */
.no-js #loader {
	display: none;
}

.js #loader {
	display: block;
	position: absolute;
	left: 100px;
	top: 0;
}

.se-pre-con {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 9999;
	background: url("../img/loader/Preloader_2.gif") center no-repeat #fff;
}

/* --------------------------------------------------
[18. Start Media Query]
-------------------------------------------------- */
@media (max-width: 383.98px) {
	.slider .content .left .btn {
		margin-bottom: 20px;
	}
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
	/* Navbar */
	.navbar {
		padding: 15px 20px;
	}

	.navbar .nav-item .nav-link {
		margin: 20px 0 10px;
	}

	.navbar .btn {
		margin: 20px 0;
		display: inline-block;
	}

	/* Slider */
	.slider {
		height: auto;
		padding: 120px 0;
	}

	.slider .content {
		text-align: center;
	}

	.slider .content .left {
		margin-bottom: 40px;
	}

	/* Features */
	.features {
		padding: 120px 0 90px;
	}

	.features .box {
		margin-bottom: 30px;
	}

	/* Some Facts */
	.some-facts {
		padding: 80px 0 30px;
	}

	.some-facts .items {
		margin-bottom: 50px;
	}

	/* Some Project */
	.project .right {
		margin-top: 40px;
	}

	/* Some Benefits */
	.benefits {
		padding: 120px 0 80px;
	}

	.benefits .box {
		margin-bottom: 40px;
	}

	/* Our Team */
	.our-team {
		padding: 120px 0 90px;
	}

	.our-team .box {
		margin-bottom: 30px;
	}

	/* Some Project-2 */
	.project-2 .left {
		margin-bottom: 40px;
	}

	/* Some Contact Us */
	.contact .content {
		margin-bottom: 40px;
	}
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
	/* Navbar */
	.navbar {
		padding: 15px 20px;
	}

	.navbar .nav-item .nav-link {
		margin: 20px 0 10px;
	}

	.navbar .btn {
		margin: 20px 0;
		display: inline-block;
	}

	/* Slider */
	.slider {
		height: auto;
		padding: 120px 0;
	}

	.slider .content {
		text-align: center;
	}

	.slider .content .left {
		margin-bottom: 40px;
	}

	/* Features */
	.features {
		padding: 120px 0 90px;
	}

	.features .box {
		margin-bottom: 30px;
	}

	/* Some Facts */
	.some-facts {
		padding: 80px 0 30px;
	}

	.some-facts .items {
		margin-bottom: 50px;
	}

	/* Some Project */
	.project .right {
		margin-top: 40px;
	}

	/* Some Benefits */
	.benefits {
		padding: 120px 0 80px;
	}

	.benefits .box {
		margin-bottom: 40px;
	}

	/* Our Team */
	.our-team {
		padding: 120px 0 90px;
	}

	.our-team .box {
		margin-bottom: 30px;
	}

	/* Team */
	.our-team .box .image .social-icon {
		width: 40%;
	}

	/* Some Project-2 */
	.project-2 .left {
		margin-bottom: 40px;
	}

	/* Some Contact Us */
	.contact .content {
		margin-bottom: 40px;
	}
}


/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
	/* Navbar */
	.navbar {
		padding: 15px 20px;
	}

	.navbar .nav-item .nav-link {
		margin: 20px 0 10px;
	}

	.navbar .btn {
		margin: 20px 0;
		display: inline-block;
	}

	/* Slider */
	.slider {
		height: auto;
		padding: 120px 0;
	}

	.slider .content .left h1 {
		font-size: 35px;
	}

	.slider .content .left p {
		font-size: 15px;
	}

	/* Some Facts */
	.some-facts {
		padding: 80px 0 30px;
	}

	.some-facts .items {
		margin-bottom: 50px;
	}

	/* Team */
	.our-team .box .image .social-icon {
		width: 70%;
	}
}

.columns-webinar {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	padding-bottom: 100px;

	.text-left.short-text {
		flex: 0 1 44%;

		.inside {
			padding-top: 50px;

			p {
				padding-top: 18px;
			}
		}
	}
}

@media (max-width: 1200px)  {
	.columns-webinar {
		display: flex;
		flex-direction: column;
		width: 100%;

		.text-left.short-text {
			width: 100%;

			.inside {
				padding-top: 50px;

				p {
					padding-top: 18px;
				}
			}
		}
	}
}

.webinar-info {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 90px 0 0 80px;

	.circle {
		display: flex;
		flex: 0 0 65px;
		justify-content: center;
		background: #eff1f7;
		align-items: center;
		width: 65px;
		height: 65px;
		border-radius: 100px;
		margin-right: 10px;

		svg {
			fill: #525c66;
			font-size: 25px;
		}
	}

	p {
		margin: 0!important;
	}
}

.clients.webinar {
	background: #fff;
}

@media (max-width: 590px)  {
	.webinar-info{
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		padding: 90px 10px 80px 10px;
		text-align: justify;
	}
}

.webinar-content {
	display: flex;
	flex-direction: column;
	align-items: center;
	background: #fff;
	padding: 70px 0 70px 0;
	box-shadow: 0 8px 84px 0 rgb(0 0 0 / 9%);
	border-radius: 10px;

	.inside {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: flex-end;

		ul {
			list-style-image: url('/img/arrow_list.png');
			line-height: 40px;
			margin: 0;
		}

		img {
			max-width: 410px;
			margin-left: 15px;
			width: 100%;
			height: auto;
		}
	}
}

@media (max-width: 768px)  {
	.webinar-content {
		display: flex;
		flex-direction: column;
		align-items: center;
		background: #fff;
		padding: 70px 0 70px 0;
		box-shadow: 0 8px 84px 0 rgb(0 0 0 / 9%);
		border-radius: 10px;

		.inside {
			display: flex;
			flex-direction: column;
			align-items: center;

			ul {
				list-style-image: url('/img/arrow_list.png');
				line-height: 40px;
				margin: 0;
			}

			img {
				max-width: 410px;
				width: 100%;
				height: auto;
				margin: 0;
			}
		}
	}
}

/* Large devices (desktops, less than 1200px) */
/*
@media (max-width: 1199.98px) {

}
*/
