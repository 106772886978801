/* Custom styles */
.subcontent-page {
	min-height: 100vh;
	padding: 150px 0 50px;
	background: white;

	h1 {
		margin-bottom: 15px;
	}

	h3 {
		font-size: 18px;
	}

	.line.photo-underline {
		transform: rotate(90deg);
		margin-top: 17px;
		width: 35px;
	}

	.capt {
		color: #57668a;
		font-size: 17px;
	}

	.desc {
		font-size: 18px;
	}
}

.custom-card {
	border-radius: calc(1.25rem - 1px);
	padding: 0;
	box-shadow: 0 4px 10px -7px rgba(0, 0, 0, 0.75);

	ul span {
		color: #57668a !important;
	}
}

.custom-img {
	border-top-left-radius: calc(1.25rem - 1px);
	border-top-right-radius: calc(1.25rem - 1px);
	max-height: 200px;
	min-height: 200px;
	object-fit: cover;
	width: 100%;
}

.custom-title {
	font-size: 21px;
	color: #3b566e;
	font-weight: bold;

	a {
		color: #3b566e;
		transition: color .3s ease;

		&:hover {
			color: #0056b3;
			text-decoration: none;
		}
	}
}

.custom-text {
	font-size: 16px !important;
	color: #8491b1 !important;

	ul {
		list-style-type: none !important;
	}
}

.custom-icon {
	font-size: 50px;
	margin-bottom: 10px;
	width: 100% !important;
}

.flex-tasks {
	flex: inherit;
	width: auto;
	margin: 0 auto;
}

.custom-line {
	background: #f1f7ff !important;
	color: #21252a !important;

	.line {
		background: linear-gradient(to right, #507bf5 0%, #04c3e1 100%) !important;
	}

	.heading {
		color: #21252a !important;
	}

	.items {
		color: #21252a !important;

		h4 {
			color: #21252a !important;
		}
	}

	.price {
		font-size: 32px;
	}

	ul {
		list-style-type: none;
		padding-left: 0;
	}
}

.profile-image {
	width: 200px;
	height: 200px;

	img {
		object-fit: cover;
	}
}

.top-border-radius {
	border-top-left-radius: calc(1.25rem - 1px);
	border-top-right-radius: calc(1.25rem - 1px);
	background: linear-gradient(to right, #507bf5 0%, #04c3e1 100%)
}

.btn-custom-color {
	background: linear-gradient(to right, #507bf5 0%, #04c3e1 100%) !important;

	&:hover {
		box-shadow: none !important;
		color: rgba(255, 255, 255, 0.8) !important;
	}
}

.page-header {
	min-height: unset !important;

	.heading h2 {
		color: #52cbf5;
		font-size: 26px;
	}
}

.size-17 {
	font-size: 17px !important;
}

.card-body-bigger {
	min-height: 257px;

	@media only screen and (max-width: 991px) {
		min-height: unset;
	}

	&.bigger {
		min-height: 281px;

		@media only screen and (max-width: 1199px) {
			min-height: 306px;
		}

		@media only screen and (max-width: 991px) {
			min-height: unset;
		}
	}
}

.card-body-smaller {
	min-height: 183px;

	@media only screen and (max-width: 991px) {
		min-height: unset;
	}

	&.smaller {
		@media only screen and (max-width: 1199px) {
			min-height: 209px;
		}

		@media only screen and (max-width: 991px) {
			min-height: unset;
		}
	}
}

.short-text {
	@media only screen and (min-width: 992px) {
		max-width: 84%;
	}

	&.p-header {
		@media only screen and (min-width: 992px) {
			max-width: 75%;
		}
	}
}

.date {
	color: #98a1b9;
}

.border-bottom-radius-0 {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}

.blog-link {
	display: inline-flex;
	align-items: center;
	color: #52cbf5;
	transition: 0.3s ease;

	.icon {
		margin-left: .25em;
	}

	&:hover {
		text-decoration: none;
	}
}

.custom-pagination {
	padding: 0 5px;

	a {
		padding: 18px 15px;
		border-radius: 5px;
		color: #3b566e;
		font-weight: bold;
		box-shadow: 0 0 10px -7px rgba(0, 0, 0, 0.75);
	}

	a:hover {
		background: white !important;
		color: #4f7cf5;
	}

	&.disabled a {
		border: 0;
		background: transparent !important;
		box-shadow: none;
	}

	&.active a {
		background: #3b566e !important;
		border: 0;
	}
}

.custom-box-shadow {
	box-shadow: 0 0 10px -7px rgba(0, 0, 0, 0.75);
}

.custom-profile-img {
	height: 100px;
	width: 100px;
	margin-top: -4.6rem;
}

.profile-card {
	margin: 2rem 0.5rem;
	max-height: 152.92px;
	transition: 0.3s ease;

	.profile-button {
		visibility: hidden;
		box-shadow: none;
		margin-top: 1rem;
		opacity: 0;
	}

	&:hover {
		max-height: 216.92px;

		.profile-button {
			visibility: visible;
			transition: opacity 0.5s ease;
			opacity: 1;
		}
	}

	&.author {
		max-height: 138px;

		&:hover {
			max-height: 216.92px;
			}
		}
}

.profile-collapsed {
	margin: 2rem 0.5rem;
	display: flex;
	flex: 1 0 48%;
	height: 100%;
}

.row.two-columns {
	flex: 1 0 50%;
	height: 100%;
}

.pagination-nav {
	ul {
		flex-wrap: wrap;
	}

	@media only screen and (max-width: 575px) {
		font-size: 80%;
	}
}

.author-wrap {
	background: #f1f7ff;
}

.author-box {
	display: flex;
	flex-wrap: wrap;
	margin-right: -1rem;
	margin-left: -1rem;
	padding-top: 3rem;

	> * {
		padding: 0 1rem;
	}

	p {
		font-size: 16px;
		color: #8491b1;

		.content-page & {
			margin-top: 0;
			margin-bottom: .5rem;
		}
	}

	.content {
		flex: 1;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	.capt {
		color: #52cbf5;
		font-size: 21px;
		font-weight: bold;
		margin-top: 1rem;
		margin-bottom: 1rem;
	}

	.name {
		font-size: 23px;
		font-weight: bold;
		margin-bottom: 0;
	}

	.more {
		display: inline-flex;
		align-items: center;
		color: #52cbf5;
		transition: .3s ease;
		text-transform: uppercase;
		margin-bottom: 1rem;
	}

	.line + & {
		margin-top: -50px;
		margin-bottom: 50px;
	}
}

.show-all {
	margin-top: 6rem;

	@media only screen and (max-width: 900px) {
		margin-top: 1rem;
	}
}

.box-shadow-none {
	box-shadow: none;
}

.row.switch-to-column {
	@media only screen and (max-width: 767px) {
		flex-direction: column;
	}
}

.p-header {
	font-size: 16px !important;
	color: #404041 !important;
}

.line-height-2 {
	line-height: 2;
}

.detail-logo {
	display: flex;
	margin: 3rem auto 0 auto !important;
	padding-bottom: 3rem;
}

.main-capt {
	width: 100%;
	max-width: 34rem;
	margin: 0 auto 1rem;

	a {
		text-decoration: underline;

		&:hover {
			text-decoration: none;
		}
	}
}

.icon.custom-icon.photo {
	margin-top: -5px;
}

.mb-50 {
	margin-bottom: 5rem!important;
}
